<template>
  <div
    class="letter-tile flex items-center justify-center font-bold rounded border border-gray-300"
    :class="statusClass"
  >
    {{ letter }}
  </div>
</template>

<script setup lang="ts">
import { computed, defineProps } from 'vue';
import { LetterStatus } from '../types/game.types';

const props = defineProps<{
  letter: string;
  status: LetterStatus;
}>();

const statusClass = computed(() => {
  switch (props.status) {
    case 'correct':
      return 'bg-red-500 text-white';
    case 'present':
      return 'bg-yellow-500 text-black';
    case 'absent':
      return 'bg-gray-400 text-white';
    default:
      return 'bg-white text-black';
  }
});
</script>

<style scoped>
.letter-tile {
  width: 2.5rem;
  height: 2.5rem;
}
</style>
