<template>
  <div class="app max-w-lg mx-auto text-center mt-8">
    <div
      v-if="loading"
      class="loader"
    >
      <!-- Ajoutez ici votre loader -->
      <h3 class="text-3xl font-normal leading-normal mt-0 mb-2 text-pink-800">
        Chargement du jeu ...
      </h3>
    </div>
    <div v-else>
      <GameBoard
        :attempts="attempts"
        :game-over="gameOver"
        :correct-word="correctWord"
      />
      <InputArea
        :is-disabled="gameOver"
        @submit="handleWordSubmission"
      />

      <Notification
        v-if="notification.show"
        :message="notification.message"
        :type="notification.type"
        :duration="notification.duration"
        @dismiss="clearNotification"
      />

      <div
        v-if="gameOver && !won"
        class="text-red-500 mt-4"
      >
        Le mot mystère est: <span class="font-bold">{{ correctWord }}</span> 
      </div>

      <button
        v-if="gameOver"
        class="replay-button bg-blue-600 text-white rounded p-2 mt-4"
        @click="restartGame"
      >
        Rejouer
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, reactive, onMounted } from 'vue';
import GameBoard from './components/GameBoard.vue';
import InputArea from './components/InputArea.vue';
import Notification from './components/Notification.vue';
import { Attempt, LetterStatus } from './types/game.types';
import { fetchWordList } from './services/WordlistService';

const MAX_TRIES = 7;
const wordLength = 6;

const wordList = ref<string[]>([]);
const correctWord = ref('');
const attempts = ref<Attempt[]>([]);
const currentAttempt = ref(0);
const gameOver = ref(false);
const won = ref(false);
const loading = ref(true);

const notification = reactive({
  show: false,
  message: '',
  type: 'info' as 'success' | 'error' | 'info',
  duration: 3000,
});

const initializeGame = async () => {
  wordList.value = await fetchWordList();

  if (wordList.value.length === 0) {
    triggerNotification('Erreur lors du chargement de la liste de mots', 'error');
    return;
  }

  // Selection d'un mot aléatoire à trouver parmi la liste récupérée de l'API
  correctWord.value = wordList.value[Math.floor(Math.random() * wordList.value.length)];

// Crée un tableau de tentatives, où chaque tentative contient un identifiant unique (id) et un tableau de lettres initialisées avec des valeurs par défaut
attempts.value = Array.from({ length: MAX_TRIES }, (_, i) => ({
  id: i,
  letters: Array.from({ length: wordLength }, () => ({ value: '', status: 'unknown' as LetterStatus })) // Tableau de lettres initialisé avec des valeurs par défaut
}));


  currentAttempt.value = 0;
  gameOver.value = false;
  won.value = false;
  loading.value = false; 
};

const handleWordSubmission = (word: string) => {
  console.log('Word submitted:', word);

  if (!wordList.value.includes(word)) {
    triggerNotification('Mot non valide', 'error');
    return;
  }

  const attempt = attempts.value[currentAttempt.value];

  for (let i = 0; i < word.length; i++) {
    attempt.letters[i].value = word[i];
    attempt.letters[i].status = (word[i] === correctWord.value[i])
      ? 'correct'
      : (correctWord.value.includes(word[i]))
        ? 'present'
        : 'absent';
  }

  if (word === correctWord.value) {
    gameOver.value = true;
    won.value = true;
    triggerNotification('Félicitations, vous avez gagné!', 'success');
  } else if (currentAttempt.value >= MAX_TRIES - 1) {
    gameOver.value = true;
    triggerNotification('Jeu terminé, vous avez perdu', 'error');
  } else {
    currentAttempt.value++;
  }
};

const triggerNotification = (message: string, type: 'success' | 'error' | 'info') => {
  notification.show = true;
  notification.message = message;
  notification.type = type;
  setTimeout(() => {
    notification.show = false;
  }, notification.duration);
};

const clearNotification = () => {
  notification.show = false;
};

const restartGame = () => {
  initializeGame(); // Réinitialise l'état du jeu
};

defineExpose({ loading, gameOver, restartGame });

onMounted(initializeGame);
</script>

<style scoped>
.replay-button:hover {
  background-color: #1e40af; 
}
</style>
