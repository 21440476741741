const port = process.env.PORT || 8080; 

let apiURI: string

 apiURI = 'https://vue-motus.zandriana.com'
export async function fetchWordList(): Promise<string[]> {
    try {
      const response = await fetch(apiURI + '/api');
  
      if (!response.ok) {
        throw new Error('Failed to fetch wordlist');
      }
  
      const wordlistText = await response.text();
      // Convertir le texte en tableau en divisant par les sauts de ligne
      const wordlistArray = wordlistText.split('\n');
      // Renvoyer un tableau clean sans espace entre les mots
      const wordlistArrayCleaned = wordlistArray.map(word => word.trim().toUpperCase());
      return wordlistArrayCleaned;
    } catch (error) {
      console.error('Erreur lors de la récupération de la liste de mots :', error);
      throw error; 
    }
  }
  