<template>
  <transition name="fade">
    <div
      v-if="show"
      class="notification p-4 text-center text-white rounded shadow-lg"
      :class="notificationClass"
    >
      {{ message }}
    </div>
  </transition>
</template>

<script setup lang="ts">
import { ref, onMounted, defineProps, defineEmits, computed } from 'vue';
import { NotificationType } from '@/types/game.types';

const props = defineProps<{
  message: string;
  type: NotificationType
  duration?: number;
}>();

const emits = defineEmits(['dismiss']);

const show = ref(true);

onMounted(() => {
  setTimeout(() => {
    show.value = false;
    emits('dismiss');
  }, props.duration || 3000); // 3000 ms par défaut
});

const notificationClass = computed(() => {
  switch (props.type) {
    case 'success':
      return 'bg-green-500';
    case 'error':
      return 'bg-red-500';
    case 'info':
      return 'bg-blue-500';
    default:
      return 'bg-white';
  }
});
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
</style>
