<template>
  <div class="input-area flex gap-2 justify-center mb-4">
    <input
      ref="inputElement"
      v-model="currentInput"
      type="text"
      :disabled="isDisabled"
      class="input-field border border-gray-300 rounded p-2"
      placeholder="Entrez un mot..."
      maxlength="6"
      @input="onInput"
      @keyup.enter="submitWord"
    >
    <button
      :disabled="isDisabled"
      class="submit-button bg-blue-600 text-white rounded p-2"
      @click="submitWord"
    >
      Soumettre
    </button>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, defineProps, defineEmits } from 'vue';

const props = defineProps<{
  isDisabled: boolean;
}>();

const emits = defineEmits(['submit']);

const currentInput = ref('');

const inputElement = ref<HTMLInputElement | null>(null);

const onInput = (event: Event) => {
  // Logique supplémentaire si nécessaire
};

const submitWord = () => {
  emits('submit', currentInput.value.toUpperCase());
  currentInput.value = ''; // Réinitialise l'entrée

  // Remet le focus dans l'input
  if (inputElement.value) {
    inputElement.value.focus();
  }
};

// Réagit aux changements de l'entrée si nécessaire
watch(currentInput, (newValue) => {
  // Logique supplémentaire si nécessaire
});
</script>

<style scoped>
.submit-button:disabled {
  background-color: #94a3b8; /* blue-gray-400 dans Tailwind */
  cursor: default;
}
</style>
